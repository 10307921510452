/* src/fonts.css */
@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRODISPLAYREGULAR.OTF') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRODISPLAYBOLD.OTF') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRODISPLAYMEDIUM.OTF') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRODISPLAYBLACKITALIC.OTF') format('opentype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRODISPLAYULTRALIGHTITALIC.OTF') format('opentype');
  font-weight: 300;
  font-style: italic;
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: 'SF Pro Display';
  overflow: hidden; /* Prevent body from adding its own scrollbar */
}

.App {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden; /* Prevent extra scrollbar in the app container */
}

.content {
  flex: 1;
  /* overflow-y: auto; Allow vertical scrolling only */
  height: 100%;
  overflow-x: hidden;
}

:root {
  --sidebar-width: 255px;
  --collapsed-width: 60px;
  --sub-sidebar-width: calc(var(--sidebar-width) + var(--collapsed-width));
}

.with-sidebar {
  margin-left: var(--sidebar-width);
}

.collapsed-sidebar {
  margin-left: var(--collapsed-width);
}

.without-sidebar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.with-subsidebar-collapsed {
  margin-left: var(--sub-sidebar-width);
}

.with-subsidebar-expanded {
  margin-left: calc(var(--sidebar-width) + var(--sidebar-width));
}

@media (max-width: 991px) {
  :root {
    --sidebar-width: 60px;
  }
  .with-sidebar,
  .collapsed-sidebar,
  .with-sunsidebar-collapsed,
  .with-sunsidebar-expanded {
    margin-left: 0;
  }
  .content {
    padding-top: 30px; 
  }
}

.rounded-box {
  width: 450px;
  height: fit-content;
  min-width: fit-content;
  background-color: #EFF4FF;
  border: 2px solid #EFF4FF;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 60px 50px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

@media (max-width: 480px) {
  .rounded-box {
    width: 350px;
    height: fit-content;
    min-width: fit-content;
    background-color: #EFF4FF;
    border: 2px solid #EFF4FF;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 40px 30px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
}
